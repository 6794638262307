import isEqual from 'lodash/isEqual';
import { useContext, useEffect, useState } from 'react';
import { ReactRelayContext, commitLocalUpdate } from 'react-relay';

import updateNumStudies from 'utils/updateNumStudies';

const pollingInterval = 3000;
const maxPollingDuration = 15000;

type TenantNumStudies = {
  tenant: {
    numViewerDraftPrimary?: number | null;
    numViewerDraftPrimarySecondary?: number | null;
    numNeedsReview?: number | null;
  } | null;
};

export function usePollStudies<TData extends TenantNumStudies>({
  queryFn,
  organizationId,
  viewerId,
}: {
  queryFn: () => Promise<TData | undefined>;
  organizationId: string;
  viewerId: string;
}) {
  const relay = useContext(ReactRelayContext);
  const [isPollingNumStudies, setIsPollingNumStudies] = useState(false);
  const startTime = Date.now();
  let numStudiesRef: TData | null = null;

  // To always display the accurate number of studies under 'My Tasks' on exam list,
  // poll updated number of studies from the search service, when active user adds or removes
  // himself as an author of the draft exam or finalize the exam that needs QA.
  const pollNumStudies = async () => {
    try {
      const result = await queryFn();

      if (
        relay?.environment &&
        result &&
        numStudiesRef &&
        !isEqual(numStudiesRef, result)
      ) {
        commitLocalUpdate(relay.environment, (store) => {
          updateNumStudies({
            organizationId,
            store,
            studies: result.tenant!,
            viewerId,
          });
        });

        setIsPollingNumStudies(false);
        return;
      }

      numStudiesRef = result ?? null;

      const elapsedTime = Date.now() - startTime;

      if (elapsedTime < maxPollingDuration) {
        setTimeout(pollNumStudies, pollingInterval);
      } else {
        setIsPollingNumStudies(false);
      }
    } catch (_) {
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime < maxPollingDuration) {
        setTimeout(pollNumStudies, pollingInterval);
      } else {
        setIsPollingNumStudies(false);
      }
    }
  };

  useEffect(() => {
    if (isPollingNumStudies) {
      pollNumStudies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollingNumStudies]);

  return {
    isPollingNumStudies,
    setIsPollingNumStudies,
    pollNumStudies,
  };
}
