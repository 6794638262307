import Layout from '@4c/layout';
import BackArrowLink from '@bfly/ui2/BackArrowLink';
import ButtonToolbar from '@bfly/ui2/ButtonToolbar';
import { LinkToProp } from '@bfly/ui2/Link';
import Text from '@bfly/ui2/Text';
import useId from '@bfly/ui2/useId';
import { css, stylesheet } from 'astroturf';
import clsx from 'clsx';
import useLocation from 'found/useLocation';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import FormattedShortDateTime from 'components/FormattedShortDateTime';
import { useVariation } from 'components/LaunchDarklyContext';
import actionMessages from 'messages/actions';
import { StudyStatus } from 'utils/StudyConstants';

import {
  useSections as useExamPageSidebarSections,
  useShowQa,
} from '../utils/examPageSidebar';
import ExamArchive from './ExamArchive';
import ExamAuthors from './ExamAuthors';
import ExamAuthorsRefresh from './ExamAuthorsRefresh';
import ExamExamType from './ExamExamType';
import ExamPageActions from './ExamPageActions';
import ExamStatus from './ExamStatus';
import ExamTags from './ExamTags';
import { ExamTopNavbar_study$data as Study } from './__generated__/ExamTopNavbar_study.graphql';

interface Props {
  to: LinkToProp;
  study: Study;
  navigationControls: React.ReactNode;
}

function ExamNavbarLabel({
  htmlFor,
  children,
  className,
}: {
  htmlFor: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx('mb-0 whitespace-nowrap text-right', className)}
    >
      {children}
    </label>
  );
}

const getStudyStatus = (
  isPendingFinalization: boolean | null,
  finalizedAt: string | null,
) => {
  const isDraft = !finalizedAt;
  if (isPendingFinalization) {
    return StudyStatus.PENDING_ATTESTATION;
  }
  if (isDraft) {
    return StudyStatus.DRAFT;
  }
  return StudyStatus.FINALIZED;
};

const styles = stylesheet`
.field-group {
  @apply grid items-center pr-6;
  column-gap: theme('spacing.3');
  row-gap: theme('spacing.3');
  grid-template-columns: minmax(9.5rem, max-content) auto;
  @screen md {
    grid-template-columns: repeat(2, minmax(9.5rem, max-content) minmax(35vw, max-content));
  }
}
`;

function ExamTopNavbar({ to, study, navigationControls }: Props) {
  const location = useLocation() as any;
  const canUseTags = useVariation('study-tags');
  const canUseMultipleAuthors = useVariation('multiple-study-authors');
  const hasSplitStudiesRefresh = useVariation('split-studies');
  const { showHeaderActions } = useExamPageSidebarSections(study);
  const authorsId = useId();
  const tagsId = useId();
  const examTypesId = useId();
  const archiveId = useId();
  const { isPendingFinalization, finalizedAt, secondaryAuthors, createdBy } =
    study;
  const studyStatus = getStudyStatus(isPendingFinalization, finalizedAt);
  const showQaColumn = useShowQa(study);
  const authorCount = (secondaryAuthors?.length || 0) + (createdBy ? 1 : 0);

  return (
    <div
      data-bni-navbar=""
      className={clsx(
        'bg-black sticky top-0 flex-shrink-0 items-center z-fixed w-full',
        studyStatus === StudyStatus.DRAFT && 'border-t-[3px] border-draft',
        studyStatus === StudyStatus.PENDING_ATTESTATION &&
          'border-t-[3px] border-red-40',
        studyStatus === StudyStatus.FINALIZED && 'border-t-[3px] border-white',
      )}
    >
      <div
        className="grid items-center z-fixed w-full"
        css={css`
          grid-template-rows: theme('height.20');
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-template-areas: 'back    patient  actions';
          @screen md-max {
            grid-template-rows: theme('height.4') theme('height.16');
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-areas:
              'back    actions'
              'patient patient';
          }
        `}
      >
        <ButtonToolbar
          style={{ gridArea: 'back' }}
          className="px-6"
          align="center"
        >
          <BackArrowLink
            to={to}
            backIndex={location.state?.studyListNavConfig?.backIndex ?? -1}
          >
            <span className="ml-3 font-semibold">
              <FormattedMessage {...actionMessages.back} />
            </span>
          </BackArrowLink>
          <ExamStatus study={study} studyStatus={studyStatus} />
        </ButtonToolbar>

        <Layout
          justify="center"
          style={{ gridArea: 'patient' }}
          className="px-6"
          data-bni-id="ExamPatientInfo"
        >
          {navigationControls}
        </Layout>

        <ButtonToolbar
          pad={2}
          align="center"
          justify="flex-end"
          className="px-6"
          style={{ gridArea: 'actions' }}
        >
          {study.capturedAt && (
            <>
              <Text>
                <FormattedMessage
                  id="examTopNavbar.captured"
                  defaultMessage="Captured"
                />
              </Text>
              <Text variant="body-bold" color="headline" transform="lowercase">
                <FormattedShortDateTime value={study.capturedAt} />
              </Text>
            </>
          )}
          <ExamPageActions study={study} />
        </ButtonToolbar>
      </div>
      <div
        className={clsx(
          'grid border-b border-t border-grey-80 py-3',
          showQaColumn
            ? 'lg:grid-cols-[1fr,66rem] xl:grid-cols-[1fr,72rem]'
            : 'lg:grid md:grid-cols-[1fr,48rem] xl:grid-cols-[1fr,60rem]',
        )}
        css={css`
          column-gap: theme('spacing.3');
          row-gap: theme('spacing.3');
          grid-template-columns: 1fr max-content;
          grid-template-rows: 1fr;
          grid-template-areas: 'authors-tags  exam-type-folder';

          @screen lg-max {
            grid-template-areas:
              'authors-tags'
              'exam-type-folder';
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
          }
        `}
      >
        <div
          style={{ gridArea: 'authors-tags' }}
          className={styles.fieldGroup}
          css={css`
            @screen lg {
              grid-template-columns:
                minmax(9.5rem, max-content) minmax(0, max-content)
                min-content auto;
            }
          `}
        >
          <ExamNavbarLabel htmlFor={authorsId}>
            {authorCount > 1 || canUseMultipleAuthors ? (
              <FormattedMessage
                id="examTopNavbar.authors"
                defaultMessage="Authors"
              />
            ) : (
              <FormattedMessage
                id="examTopNavbar.author"
                defaultMessage="Author"
              />
            )}
          </ExamNavbarLabel>
          <div>
            {hasSplitStudiesRefresh ? (
              <ExamAuthorsRefresh study={study} />
            ) : (
              <ExamAuthors study={study} id={authorsId} />
            )}
          </div>
          {canUseTags && showHeaderActions && (
            <>
              <ExamNavbarLabel htmlFor={tagsId}>
                <FormattedMessage
                  id="examTopNavbar.tags"
                  defaultMessage="Tags"
                />
              </ExamNavbarLabel>
              <div className="block">
                <ExamTags study={study} id={tagsId} className="max-w-full" />
              </div>
            </>
          )}
        </div>
        <div
          style={{ gridArea: 'exam-type-folder' }}
          className={styles.fieldGroup}
          css={css`
            @screen lg {
              grid-template-columns:
                min-content minmax(0, max-content)
                min-content auto;
            }
          `}
        >
          {showHeaderActions && (
            <>
              <ExamNavbarLabel htmlFor={examTypesId}>
                <FormattedMessage
                  id="examTopNavbar.examType"
                  defaultMessage="Exam type"
                />
              </ExamNavbarLabel>
              <ExamExamType
                study={study}
                id={examTypesId}
                className="xl:w-40 w-full"
              />
            </>
          )}
          <ExamNavbarLabel htmlFor={archiveId}>
            <FormattedMessage
              id="examTopNavbar.archive"
              defaultMessage="Archive"
            />
          </ExamNavbarLabel>
          <ExamArchive study={study} id={archiveId} className="w-full" />
        </div>
      </div>
    </div>
  );
}

export default createFragmentContainer(ExamTopNavbar, {
  study: graphql`
    fragment ExamTopNavbar_study on Study {
      handle
      capturedAt
      isPendingFinalization
      finalizedAt
      createdBy {
        __typename
      }
      secondaryAuthors {
        __typename
      }
      ...ExamStatus_study
      ...ExamPageActions_study
      ...ExamAuthors_study
      ...ExamAuthorsRefresh_study
      ...HeaderPatientInfo_study
      ...ExamTags_study
      ...ExamArchive_study
      ...ExamExamType_study
      ...examPageSidebar_useSections_study
      ...examPageSidebar_useShowQa_study
      ...ExamNav_study
    }
  `,
});
