import Layout from '@4c/layout';
import Dropdown from '@bfly/ui2/Dropdown';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';

import AvatarList from './AvatarList';
import { StudyAuthorsListItem_RemoveAuthorMutation as RemoveAuthorMutation } from './__generated__/StudyAuthorsListItem_RemoveAuthorMutation.graphql';
import { StudyAuthorsListItem_SetPrimaryAuthorMutation as SetPrimaryAuthorMutation } from './__generated__/StudyAuthorsListItem_SetPrimaryAuthorMutation.graphql';
import { StudyAuthorsListItem_SetScribeMutation as SetScribeMutation } from './__generated__/StudyAuthorsListItem_SetScribeMutation.graphql';
import { StudyAuthorsListItem_UnsetScribeMutation as UnsetScribeMutation } from './__generated__/StudyAuthorsListItem_UnsetScribeMutation.graphql';
import { StudyAuthorsListItem_author$data as Author } from './__generated__/StudyAuthorsListItem_author.graphql';
import { StudyAuthorsListItem_study$data as Study } from './__generated__/StudyAuthorsListItem_study.graphql';

interface Props {
  author: Author;
  avatarSize?: number;
  isPrimary: boolean;
  isScribe: boolean;
  study: Study;
  authorsCount: number;
}

function StudyAuthorsListItem({
  author,
  avatarSize,
  isPrimary,
  isScribe,
  study,
  authorsCount,
}: Props) {
  const canUseMultipleAuthors = useVariation('multiple-study-authors');

  // allow multiple author controls if
  // flag enabled
  // OR there is no primary author
  // OR there is more than one user
  const allowMultipleAuthors =
    canUseMultipleAuthors || !study.createdBy || authorsCount > 1;

  const canUseScribes = useVariation('study-scribes') && canUseMultipleAuthors;
  const { readOnly } = useMemo(
    () => ({
      organizationId: study!.organization!.id!,
      readOnly: !!study.finalizedAt,
    }),
    [study],
  );

  const [removeAuthor] = useMutationWithError<RemoveAuthorMutation>(
    graphql`
      mutation StudyAuthorsListItem_RemoveAuthorMutation(
        $input: RemoveStudyAuthorInput!
      ) {
        removeStudyAuthorOrError(input: $input) {
          ... on RemoveStudyAuthorPayload {
            study {
              ...StudyPermissions_allStudyPermissions
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      input: {
        studyId: study.id,
        authorUserId: author.id,
      },
    },
  );

  const [setPrimaryAuthor] = useMutationWithError<SetPrimaryAuthorMutation>(
    graphql`
      mutation StudyAuthorsListItem_SetPrimaryAuthorMutation(
        $input: SetStudyPrimaryAuthorInput!
      ) {
        setStudyPrimaryAuthorOrError(input: $input) {
          ... on SetStudyPrimaryAuthorPayload {
            study {
              ...StudyPermissions_allStudyPermissions
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      input: {
        studyId: study.id,
        authorUserId: author.id,
      },
      updater: (store) => {
        rangeDeleteUpdater(store, {
          parentId: study.organization!.id,
          connectionKey: 'Organization_studyConnection',
          connectionFilters: (vars) => {
            const authorFilter = vars?.author || vars?.primaryAuthor || [];
            return Array.isArray(authorFilter)
              ? authorFilter.includes('@@UNASSIGNED')
              : false;
          },
          deletedId: study.id,
        });
        rangeDeleteUpdater(store, {
          parentId: study.organization!.id,
          connectionKey: 'Organization_studySearchConnection',
          connectionFilters: (vars) => {
            const authorFilter = vars?.search?.author || [];
            return Array.isArray(authorFilter)
              ? authorFilter.includes('@@UNASSIGNED')
              : false;
          },
          deletedId: study.id,
        });
      },
    },
  );

  const [toggleScribe] = useMutationWithError<
    SetScribeMutation | UnsetScribeMutation
  >(
    isScribe
      ? graphql`
          mutation StudyAuthorsListItem_UnsetScribeMutation(
            $input: UnsetStudyScribeInput!
          ) {
            unsetStudyScribeOrError(input: $input) {
              ... on UnsetStudyScribePayload {
                study {
                  transcribedBy {
                    id
                  }
                }
              }
              ...mutationError_error @relay(mask: false)
            }
          }
        `
      : graphql`
          mutation StudyAuthorsListItem_SetScribeMutation(
            $input: SetStudyScribeInput!
          ) {
            setStudyScribeOrError(input: $input) {
              ... on SetStudyScribePayload {
                study {
                  transcribedBy {
                    id
                  }
                }
              }
              ...mutationError_error @relay(mask: false)
            }
          }
        `,
    {
      input: {
        studyId: study.id,
        authorUserId: author.id,
      },
    },
  );

  return (
    <AvatarList.Item avatarSize={avatarSize} user={author}>
      <Layout direction="column">
        <Text variant="body" color="headline">
          {author!.name}
        </Text>
      </Layout>
      {allowMultipleAuthors && (
        <Layout pad align="center">
          {isPrimary && (
            <Text color="subtitle">
              <FormattedMessage
                id="studyAuthorsListItem.primary"
                defaultMessage="Primary Author"
              />
            </Text>
          )}
          {canUseScribes && isScribe && (
            <Text color="subtitle">
              <FormattedMessage
                id="studyAuthorsListItem.scribe"
                defaultMessage="Scribe"
              />
            </Text>
          )}

          {!readOnly && (
            <MeatballDropdownButton
              data-bni-id="StudyAuthorsListItemSettings"
              id={`author-actions-${author.id}`}
              popperConfig={{ strategy: 'fixed' }}
            >
              {!isPrimary && (
                <>
                  {!isScribe && (
                    <Dropdown.Item onClick={() => setPrimaryAuthor()}>
                      <FormattedMessage
                        id="studyAuthorsListItem.action.setPrimary"
                        defaultMessage="Set as primary author"
                      />
                    </Dropdown.Item>
                  )}
                  {canUseScribes && (
                    <Dropdown.Item onClick={() => toggleScribe()}>
                      {isScribe ? (
                        <FormattedMessage
                          id="studyAuthorsListItem.action.unsetScribe"
                          defaultMessage="Remove study scribe"
                        />
                      ) : (
                        <FormattedMessage
                          id="studyAuthorsListItem.action.setScribe"
                          defaultMessage="Set as study scribe"
                        />
                      )}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item variant="danger" onClick={() => removeAuthor()}>
                <div>
                  <FormattedMessage
                    id="studyAuthorsListItem.action.delete"
                    defaultMessage="Remove author"
                  />
                </div>
              </Dropdown.Item>
            </MeatballDropdownButton>
          )}
        </Layout>
      )}
    </AvatarList.Item>
  );
}

export default createFragmentContainer(StudyAuthorsListItem, {
  study: graphql`
    fragment StudyAuthorsListItem_study on Study {
      finalizedAt
      id
      createdBy {
        __typename
      }
      organization {
        id
      }
      ...studyAuthors_study
    }
  `,
  author: graphql`
    fragment StudyAuthorsListItem_author on UserProfile {
      id
      name
      ...AvatarList_user
    }
  `,
});
