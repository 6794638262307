/**
 * @generated SignedSource<<9c0b24b985157b2097a6a3ab93db361d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExamAuthorsRefresh_NumStudiesQuery$variables = {
  viewerId?: ReadonlyArray<string> | null;
};
export type ExamAuthorsRefresh_NumStudiesQuery$data = {
  readonly tenant: {
    readonly numViewerDraftPrimary: number | null;
    readonly numViewerDraftPrimarySecondary: number | null;
  } | null;
};
export type ExamAuthorsRefresh_NumStudiesQuery = {
  response: ExamAuthorsRefresh_NumStudiesQuery$data;
  variables: ExamAuthorsRefresh_NumStudiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
],
v1 = {
  "kind": "Literal",
  "name": "excludedFromStudyLists",
  "value": [
    false
  ]
},
v2 = {
  "kind": "Literal",
  "name": "status",
  "value": [
    "DRAFT"
  ]
},
v3 = {
  "alias": "numViewerDraftPrimary",
  "args": [
    {
      "fields": [
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "primaryAuthor",
          "variableName": "viewerId"
        },
        (v2/*: any*/)
      ],
      "kind": "ObjectValue",
      "name": "search"
    }
  ],
  "kind": "ScalarField",
  "name": "numStudies",
  "storageKey": null
},
v4 = {
  "alias": "numViewerDraftPrimarySecondary",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "author",
          "variableName": "viewerId"
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "kind": "ObjectValue",
      "name": "search"
    }
  ],
  "kind": "ScalarField",
  "name": "numStudies",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamAuthorsRefresh_NumStudiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamAuthorsRefresh_NumStudiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e79c1e8e22e8add3fa63a1e94f143fb",
    "id": null,
    "metadata": {},
    "name": "ExamAuthorsRefresh_NumStudiesQuery",
    "operationKind": "query",
    "text": "query ExamAuthorsRefresh_NumStudiesQuery(\n  $viewerId: [ID!]\n) {\n  tenant {\n    __typename\n    numViewerDraftPrimary: numStudies(search: {primaryAuthor: $viewerId, status: [DRAFT], excludedFromStudyLists: [false]})\n    numViewerDraftPrimarySecondary: numStudies(search: {author: $viewerId, status: [DRAFT], excludedFromStudyLists: [false]})\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "916a2a3c66bb480aa9f8aa35dfcc8bb0";

export default node;
