import RevertDraftIcon from '@bfly/icons/RevertDraft';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql, readInlineData } from 'react-relay';

import { useCanMarkAsDraft } from 'utils/StudyPermissions';
import { usePermissions } from 'utils/viewerContext';

import { MarkStudyAsDraftControlMutation } from './__generated__/MarkStudyAsDraftControlMutation.graphql';
import { MarkStudyAsDraftControl_study$data as Study } from './__generated__/MarkStudyAsDraftControl_study.graphql';
import { MarkStudyAsDraftControl_useRevertToDraft_study$key as UseRevertToDraftStudyKey } from './__generated__/MarkStudyAsDraftControl_useRevertToDraft_study.graphql';

interface Props {
  as: React.ElementType;
  study: Study;
}

const mutation = graphql`
  mutation MarkStudyAsDraftControlMutation($input: MarkStudyAsDraftInput!) {
    markStudyAsDraftOrError(input: $input) {
      ... on MarkStudyAsDraftPayload {
        study {
          finalizedAt
          hasBeenFinalized
          finalizedBy {
            name
          }
          ...StudyPermissions_allStudyPermissions
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

export function useRevertToDraft(studyRef: UseRevertToDraftStudyKey) {
  const dialog = useDialog();
  const toast = useToast();
  const { hasBasicPermission } = usePermissions();

  const study = readInlineData(
    graphql`
      fragment MarkStudyAsDraftControl_useRevertToDraft_study on Study
      @inline {
        id
        organization {
          id
        }
        ...StudyPermissions_canMarkAsDraft
      }
    `,
    studyRef,
  );

  const canMarkAsDraft = useCanMarkAsDraft(study);

  const [mutate] = useMutationWithError<MarkStudyAsDraftControlMutation>(
    mutation,
    {
      input: { studyId: study.id },
    },
  );

  const handleClick = useCallback(async () => {
    const markedDraft = await dialog.open(
      <FormattedMessage
        id="study.markDraft.dialog.content"
        defaultMessage="The study will be editable by all members of the archive. Additionally, if you have configured a PACS or EHR the study will be sent again when it is re-finalized."
      />,
      {
        runConfirm: mutate,
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        modalVariant: 'dark',
        title: (
          <FormattedMessage
            id="study.markDraft.dialog.title"
            defaultMessage="Revert to draft?"
          />
        ),
        confirmLabel: (
          <FormattedMessage
            id="study.markDraft.dialog.action"
            defaultMessage="Revert to draft"
          />
        ),
      },
    );

    if (markedDraft) {
      toast.success(
        <FormattedMessage
          id="study.markDraft.dialog.success"
          defaultMessage="Study reverted to draft."
        />,
      );
    }
  }, [dialog, mutate, toast]);

  return [
    canMarkAsDraft && hasBasicPermission('studyReversion'),
    handleClick,
  ] as const;
}

function MarkDraftControl({ as: Component, study }: Props) {
  const [canMarkAsDraft, onClick] = useRevertToDraft(study);

  return canMarkAsDraft ? (
    <Component
      icon={<RevertDraftIcon />}
      onClick={onClick}
      variant="danger"
      data-bni-id="RevertDraftControl"
    >
      <FormattedMessage
        id="study.markDraft"
        defaultMessage="Revert to draft"
      />
    </Component>
  ) : null;
}

export default createFragmentContainer(MarkDraftControl, {
  study: graphql`
    fragment MarkStudyAsDraftControl_study on Study {
      ...MarkStudyAsDraftControl_useRevertToDraft_study
    }
  `,
});
