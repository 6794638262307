import useMutationWithError from '@bfly/ui2/useMutationWithError';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { graphql } from 'relay-runtime';

import { ExamAuthorMutations_AddAuthorMutation as AddAuthorMutation } from './__generated__/ExamAuthorMutations_AddAuthorMutation.graphql';
import { ExamAuthorMutations_RemoveAuthorMutation as RemoveAuthorMutation } from './__generated__/ExamAuthorMutations_RemoveAuthorMutation.graphql';
import { ExamAuthorMutations_ReplaceAuthorMutation as ReplaceAuthorMutation } from './__generated__/ExamAuthorMutations_ReplaceAuthorMutation.graphql';
import { ExamAuthorMutations_SetPrimaryAuthorMutation as SetPrimaryAuthorMutation } from './__generated__/ExamAuthorMutations_SetPrimaryAuthorMutation.graphql';
import { ExamAuthorMutations_SetScribeMutation as SetScribeMutation } from './__generated__/ExamAuthorMutations_SetScribeMutation.graphql';
import { ExamAuthorMutations_UnsetScribeMutation as UnsetScribeMutation } from './__generated__/ExamAuthorMutations_UnsetScribeMutation.graphql';

export default function useExamAuthorMutations(
  organizationId: string,
  studyId: string,
) {
  const [removeAuthor, removeAuthorLoading] =
    useMutationWithError<RemoveAuthorMutation>(
      graphql`
        mutation ExamAuthorMutations_RemoveAuthorMutation(
          $input: RemoveStudyAuthorInput!
        ) {
          removeStudyAuthorOrError(input: $input) {
            ... on RemoveStudyAuthorPayload {
              study {
                ...StudyPermissions_allStudyPermissions
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
    );

  const [setPrimaryAuthor, setPrimaryAuthorLoading] =
    useMutationWithError<SetPrimaryAuthorMutation>(
      graphql`
        mutation ExamAuthorMutations_SetPrimaryAuthorMutation(
          $input: SetStudyPrimaryAuthorInput!
        ) {
          setStudyPrimaryAuthorOrError(input: $input) {
            ... on SetStudyPrimaryAuthorPayload {
              study {
                ...StudyPermissions_allStudyPermissions
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
      {
        updater: (store) => {
          rangeDeleteUpdater(store, {
            parentId: organizationId,
            connectionKey: 'Organization_studyConnection',
            connectionFilters: (vars) => {
              const authorFilter = vars?.author || vars?.primaryAuthor || [];
              return Array.isArray(authorFilter)
                ? authorFilter.includes('@@UNASSIGNED')
                : false;
            },
            deletedId: studyId,
          });
          rangeDeleteUpdater(store, {
            parentId: organizationId,
            connectionKey: 'Organization_studySearchConnection',
            connectionFilters: (vars) => {
              const authorFilter = vars?.search?.author || [];
              return Array.isArray(authorFilter)
                ? authorFilter.includes('@@UNASSIGNED')
                : false;
            },
            deletedId: studyId,
          });
        },
      },
    );

  const [setScribe, setScribeLoading] =
    useMutationWithError<SetScribeMutation>(
      graphql`
        mutation ExamAuthorMutations_SetScribeMutation(
          $input: SetStudyScribeInput!
        ) {
          setStudyScribeOrError(input: $input) {
            ... on SetStudyScribePayload {
              study {
                transcribedBy {
                  id
                }
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
    );

  const [unsetScribe, unsetScribeLoading] =
    useMutationWithError<UnsetScribeMutation>(
      graphql`
        mutation ExamAuthorMutations_UnsetScribeMutation(
          $input: UnsetStudyScribeInput!
        ) {
          unsetStudyScribeOrError(input: $input) {
            ... on UnsetStudyScribePayload {
              study {
                transcribedBy {
                  id
                }
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
      {
        updater: (store) => {
          store.get(studyId!)!.setValue(null, 'transcribedBy');
        },
      },
    );

  const [addAuthor, addUserLoading] = useMutationWithError<AddAuthorMutation>(
    graphql`
      mutation ExamAuthorMutations_AddAuthorMutation(
        $input: AddStudyAuthorInput!
      ) {
        addStudyAuthorOrError(input: $input) {
          ... on AddStudyAuthorPayload {
            study {
              ...StudyPermissions_allStudyPermissions
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      updater: (store) => {
        rangeDeleteUpdater(store, {
          parentId: organizationId,
          connectionKey: 'Organization_studyConnection',
          connectionFilters: (vars) => {
            const authorFilter = vars?.author || vars?.primaryAuthor || [];
            return Array.isArray(authorFilter)
              ? authorFilter.includes('@@UNASSIGNED')
              : false;
          },
          deletedId: studyId,
        });
        rangeDeleteUpdater(store, {
          parentId: organizationId,
          connectionKey: 'Organization_studySearchConnection',
          connectionFilters: (vars) => {
            const authorFilter = vars?.search?.author || [];
            return Array.isArray(authorFilter)
              ? authorFilter.includes('@@UNASSIGNED')
              : false;
          },
          deletedId: studyId,
        });
      },
    },
  );

  const [replaceAuthor, replaceUserLoading] =
    useMutationWithError<ReplaceAuthorMutation>(
      graphql`
        mutation ExamAuthorMutations_ReplaceAuthorMutation(
          $input: ReplaceStudyPrimaryAuthorInput!
        ) {
          replaceStudyPrimaryAuthorOrError(input: $input) {
            ... on ReplaceStudyPrimaryAuthorPayload {
              study {
                createdBy {
                  name
                  ...Avatar_userProfile
                }
                ...StudyPermissions_allStudyPermissions
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
    );

  return {
    removeAuthor,
    setPrimaryAuthor,
    setScribe,
    unsetScribe,
    addAuthor,
    replaceAuthor,
    loading:
      unsetScribeLoading ||
      setScribeLoading ||
      removeAuthorLoading ||
      setPrimaryAuthorLoading ||
      addUserLoading ||
      replaceUserLoading,
  };
}
