import notNullish from '@bfly/utils/notNullish';
import {
  type RelayProp,
  createFragmentContainer,
  fetchQuery,
  graphql,
} from 'react-relay';

import ExamAuthorsField, {
  type ExamAuthorsFieldProps,
} from 'components/ExamAuthorsField';
import { useVariation } from 'components/LaunchDarklyContext';
import { usePollStudies } from 'hooks/usePollStudies';
import useExamAuthorMutations from 'mutations/ExamAuthorMutations';
import Analytics, { AnalyticsEvent } from 'utils/Analytics';
import { useViewerContext } from 'utils/viewerContext';

import {
  ExamAuthorsRefresh_NumStudiesQuery$data,
  ExamAuthorsRefresh_NumStudiesQuery as RefreshNumStudiesQuery,
} from './__generated__/ExamAuthorsRefresh_NumStudiesQuery.graphql';
import { ExamAuthorsRefresh_study$data as Study } from './__generated__/ExamAuthorsRefresh_study.graphql';

interface Props {
  relay: RelayProp;
  study: Study;
}

function ExamAuthorsRefresh({ study, relay }: Props) {
  const studyId = study.id;
  const archiveId = study.archive!.id;
  const organizationId = study.organization!.id;
  const hasPrimaryAuthor = !!study?.createdBy;
  const viewer = useViewerContext();
  const { setIsPollingNumStudies } =
    usePollStudies<ExamAuthorsRefresh_NumStudiesQuery$data>({
      queryFn: () =>
        fetchQuery<RefreshNumStudiesQuery>(
          relay.environment,
          graphql`
            query ExamAuthorsRefresh_NumStudiesQuery($viewerId: [ID!]) {
              tenant {
                numViewerDraftPrimary: numStudies(
                  search: {
                    primaryAuthor: $viewerId
                    status: [DRAFT]
                    excludedFromStudyLists: [false]
                  }
                )
                numViewerDraftPrimarySecondary: numStudies(
                  search: {
                    author: $viewerId
                    status: [DRAFT]
                    excludedFromStudyLists: [false]
                  }
                )
              }
            }
          `,
          { viewerId: [viewer.profile!.id] },
        ).toPromise(),
      viewerId: viewer.id,
      organizationId,
    });

  const {
    removeAuthor,
    setPrimaryAuthor,
    setScribe,
    unsetScribe,
    addAuthor,
    replaceAuthor,
    loading,
  } = useExamAuthorMutations(organizationId, studyId);

  const allowMultipleAuthorControls = useVariation('multiple-study-authors');

  const analyticsTrack = (userId: string, event: AnalyticsEvent) =>
    Analytics.track(event, {
      studyId,
      organizationId,
      userId,
    });

  const handleAuthorChange: ExamAuthorsFieldProps['onChangeAction'] = (
    user,
    action,
  ) => {
    const userId = user.id;
    const input = { authorUserId: userId, studyId: study.id };

    if (action === 'removeAuthor') {
      removeAuthor({ input });
      analyticsTrack(userId, 'authorFieldAuthorRemoved');

      if (input.authorUserId === viewer.profile?.id) {
        setIsPollingNumStudies(true);
      }
      return;
    }
    if (action === 'select') {
      if (allowMultipleAuthorControls || !hasPrimaryAuthor) {
        addAuthor({ input });
        analyticsTrack(userId, 'authorFieldAuthorAdded');

        if (input.authorUserId === viewer.profile?.id) {
          setIsPollingNumStudies(true);
        }
      } else {
        replaceAuthor({ input });
        analyticsTrack(userId, 'authorFieldAuthorReplaced');

        if (input.authorUserId === viewer.profile?.id) {
          setIsPollingNumStudies(true);
        }
      }
      return;
    }
    if (action === 'setPrimaryAuthor') {
      setPrimaryAuthor({ input });
      analyticsTrack(userId, 'authorFieldPrimaryAuthorSet');

      if (input.authorUserId === viewer.profile?.id) {
        setIsPollingNumStudies(true);
      }
      return;
    }
    if (action === 'toggleScribe') {
      if (user.id !== study.transcribedBy?.id) {
        setScribe({ input });
        analyticsTrack(userId, 'authorFieldScribeSet');
      } else {
        unsetScribe({ input });
        analyticsTrack(userId, 'authorFieldScribeUnset');
      }
    }
  };

  return (
    <ExamAuthorsField
      archiveId={archiveId}
      organizationId={organizationId}
      studyId={studyId}
      loading={loading}
      value={{
        createdBy: study.createdBy?.id || undefined,
        secondaryAuthors: study.secondaryAuthors?.map((a) => a!.id!) || [],
        transcribedBy: study.transcribedBy?.id || undefined,
      }}
      onChangeAction={(user, action) => handleAuthorChange(user, action)}
      readOnly={!!study.finalizedAt}
      users={[study.createdBy, ...(study.secondaryAuthors || [])].filter(
        notNullish,
      )}
      showDefaultUser
    />
  );
}

export default createFragmentContainer(ExamAuthorsRefresh, {
  study: graphql`
    fragment ExamAuthorsRefresh_study on Study {
      id
      archive {
        id
      }
      organization {
        id
      }
      finalizedAt
      createdBy {
        id
        ...ExamAuthorsField_users
      }
      secondaryAuthors {
        id
        ...ExamAuthorsField_users
      }
      transcribedBy {
        id
        ...ExamAuthorsField_users
      }
    }
  `,
});
