import SideMenu from '@bfly/ui2/SideMenu';
import clsx from 'clsx';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { StudyStatusColor } from 'components/StudyStatus';
import { StudyListFilter } from 'utils/StudyConstants';
import { usePermissions } from 'utils/viewerContext';

import SectionFilterItem from './SectionFilterItem';
import { NewTodoSection_tenant$data as Tenant } from './__generated__/NewTodoSection_tenant.graphql';

const messages = defineMessages({
  mySignatureReq: {
    id: 'new-todo-section-my-signature-requested-studies',
    defaultMessage: 'Signature',
  },
  needsReview: {
    id: 'new-todo-section-needs-review-studies',
    defaultMessage: 'Needs QA',
  },
  myDrafts: {
    id: 'new-todo-section-my-draft',
    defaultMessage: 'Drafts',
  },
});
interface Props {
  tenant: Tenant;
}

const NewTodoSection = ({ tenant }: Props) => {
  const { hasBasicPermission } = usePermissions();
  const {
    viewerCanFinalize,
    numViewerDraftPrimary,
    numViewerDraftPrimarySecondary,
    numViewerSignatureRequested,
    numNeedsReview,
  } = tenant;
  const canSign = useVariation('restricted-study-finalization');
  const signRights = canSign && viewerCanFinalize;

  /*
    If a viewer has signing rights, they should see studies that they are also a secondary author of in '/my-drafts'.
    Pass information about that in query, which <Route /> component will use
  */
  const draftsQuery = signRights ? { canSign: true } : {};

  const canUseQa = useVariation('worksheets-review');
  const canUseDrafts = useVariation('draft-studies');
  const qaRights = canUseQa && hasBasicPermission('qa');

  if (!signRights && !canUseDrafts && !qaRights) {
    return null;
  }

  return (
    <>
      <SideMenu.ListItem>
        <SideMenu.Header
          data-bni-id="MyTasksTitle"
          id="todo-header"
          aria-controls="todo-section"
          transform="capitalize"
          variant="body-bold"
          className="ml-2"
        >
          <FormattedMessage
            id="new-todo-section-header-text"
            defaultMessage="My Tasks"
          />
        </SideMenu.Header>
        <SideMenu>
          {canUseDrafts && (
            <SectionFilterItem
              id="navbar-studies-my-drafts"
              nextQuery={draftsQuery}
              badge={
                signRights
                  ? numViewerDraftPrimarySecondary
                  : numViewerDraftPrimary
              }
              icon={
                <div className={clsx('w-2 h-2', StudyStatusColor.DRAFT)} />
              }
              message={messages.myDrafts}
              routeName={StudyListFilter.MY_DRAFTS}
            />
          )}
          {signRights && (
            <SectionFilterItem
              id="navbar-studies-my-sign-req"
              badge={numViewerSignatureRequested}
              icon={
                <div
                  className={clsx(
                    'w-2 h-2',
                    StudyStatusColor.PENDING_ATTESTATION,
                  )}
                />
              }
              message={messages.mySignatureReq}
              routeName={StudyListFilter.MY_SIGNATURE_REQUESTED}
            />
          )}
          {qaRights && (
            <SectionFilterItem
              id="navbar-studies-needs-review"
              badge={numNeedsReview}
              icon={<div className="w-2 h-2" />}
              message={messages.needsReview}
              routeName={StudyListFilter.NEEDS_REVIEW}
            />
          )}
        </SideMenu>
      </SideMenu.ListItem>
      <SideMenu.Divider />
    </>
  );
};

export default createFragmentContainer(NewTodoSection, {
  tenant: graphql`
    fragment NewTodoSection_tenant on TenantInterface {
      viewerCanQa
      viewerCanFinalize
      numViewerDraftPrimary: numStudies(
        search: {
          primaryAuthor: $viewerId
          status: [DRAFT]
          excludedFromStudyLists: [false]
        }
      )
      numViewerDraftPrimarySecondary: numStudies(
        search: {
          author: $viewerId
          status: [DRAFT]
          excludedFromStudyLists: [false]
        }
      )
      numViewerSignatureRequested: numStudies(
        search: {
          requestedFinalizer: $viewerId
          status: [PENDING_ATTESTATION]
          excludedFromStudyLists: [false]
        }
      )
      numNeedsReview: numStudies(
        search: { status: [NEEDS_REVIEW], excludedFromStudyLists: [false] }
      )
    }
  `,
});
