import { globalIdToLocalId } from '@bfly/utils/codecs';
import { RecordSourceProxy } from 'relay-runtime';

export default function updateNumStudies(opts: {
  organizationId: string;
  store: RecordSourceProxy;
  studies: {
    numNeedsReview?: number | null;
    numViewerDraftPrimary?: number | null;
    numViewerDraftPrimarySecondary?: number | null;
  };
  viewerId: string;
}) {
  const { organizationId, viewerId, store, studies } = opts;
  // In the main query, we use local ids to get data, so we need to do the same here
  const localViewerId = globalIdToLocalId(viewerId);
  const orgNode = store.get(organizationId);

  if (studies?.numViewerDraftPrimary) {
    orgNode?.setValue(studies.numViewerDraftPrimary, 'numStudies', {
      search: {
        primaryAuthor: localViewerId,
        status: ['DRAFT'],
        excludedFromStudyLists: [false],
      },
    });
  }
  if (studies?.numViewerDraftPrimarySecondary) {
    orgNode?.setValue(studies.numViewerDraftPrimarySecondary, 'numStudies', {
      search: {
        author: localViewerId,
        status: ['DRAFT'],
        excludedFromStudyLists: [false],
      },
    });

    if (studies?.numNeedsReview)
      orgNode?.setValue(studies.numNeedsReview, 'numStudies', {
        search: { status: ['NEEDS_REVIEW'], excludedFromStudyLists: [false] },
      });
  }
}
