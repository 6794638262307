/**
 * @generated SignedSource<<415d4c1c11f8487a9a0c8dc4b5805310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExamPageSidebarFooter_NumStudiesQuery$variables = {};
export type ExamPageSidebarFooter_NumStudiesQuery$data = {
  readonly tenant: {
    readonly numNeedsReview: number | null;
  } | null;
};
export type ExamPageSidebarFooter_NumStudiesQuery = {
  response: ExamPageSidebarFooter_NumStudiesQuery$data;
  variables: ExamPageSidebarFooter_NumStudiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "numNeedsReview",
  "args": [
    {
      "kind": "Literal",
      "name": "search",
      "value": {
        "excludedFromStudyLists": [
          false
        ],
        "status": [
          "NEEDS_REVIEW"
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "numStudies",
  "storageKey": "numStudies(search:{\"excludedFromStudyLists\":[false],\"status\":[\"NEEDS_REVIEW\"]})"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamPageSidebarFooter_NumStudiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExamPageSidebarFooter_NumStudiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd10462a0ce42b4d39461b6bbb498b70",
    "id": null,
    "metadata": {},
    "name": "ExamPageSidebarFooter_NumStudiesQuery",
    "operationKind": "query",
    "text": "query ExamPageSidebarFooter_NumStudiesQuery {\n  tenant {\n    __typename\n    numNeedsReview: numStudies(search: {status: [NEEDS_REVIEW], excludedFromStudyLists: [false]})\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "855752ebbd7ddf64d5804f438749dd62";

export default node;
