import CheckMarkSmallIcon from '@bfly/icons/CheckMarkSmall';
import CloseSmIcon from '@bfly/icons/CloseSm';
import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Popover from '@bfly/ui2/Popover';
import ProgressBar from '@bfly/ui2/Progress';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useRouter } from 'found';
import { ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import {
  type RelayProp,
  createFragmentContainer,
  fetchQuery,
} from 'react-relay';
import { graphql } from 'relay-runtime';
import type { SchemaObjectDescription } from 'yup/lib/schema';

import { useVariation } from 'components/LaunchDarklyContext';
import PromptButton from 'components/PromptButton';
import { usePollStudies } from 'hooks/usePollStudies';
import actionMessages from 'messages/actions';
import examMessages from 'messages/examMessages';
import { useExamRoutes } from 'routes/exam';
import {
  deserializeFromSchema,
  templateVersionToSchema,
} from 'schemas/worksheet';
import Analytics, { AnalyticsEvent } from 'utils/Analytics';
import { canFinalizeStudy } from 'utils/StudyPermissions';
import { useViewerContext } from 'utils/viewerContext';

import { useSections } from '../utils/examPageSidebar';
import useStudyFinalization, {
  FinalizationMode,
  StudyFinalizationRestriction,
} from '../utils/useStudyFinalization';
import { useExamContext, useExamSetterContext } from './ExamContext';
import ExamPageSidebarQaFooter from './ExamPageSidebarQaFooter';
import RequestFinalizationModal from './RequestFinalizationModal';
import {
  ExamPageSidebarFooter_NumStudiesQuery$data,
  ExamPageSidebarFooter_NumStudiesQuery as RefreshNumStudiesQuery,
} from './__generated__/ExamPageSidebarFooter_NumStudiesQuery.graphql';
import { ExamPageSidebarFooter_study$data as Study } from './__generated__/ExamPageSidebarFooter_study.graphql';

interface Progress {
  numRequiredFilled: number;
  numRequired: number;
}

const checkIcon = (
  <CheckMarkSmallIcon height={14} width={12} className="mt-1" />
);
const closeIcon = <CloseSmIcon height={12} className="mt-1" />;

const accumulateProgress = (
  desc: SchemaObjectDescription,
  values: Record<string, any>,
  progress: Progress,
) => {
  const valueDesc = desc.fields.values as SchemaObjectDescription;

  for (const [id, spec] of Object.entries(valueDesc.fields)) {
    if ('tests' in spec && spec.tests.some((t) => t.name === 'required')) {
      progress.numRequired++;

      if (!values[id] || (Array.isArray(values[id]) && !values[id].length)) {
        continue;
      }
      progress.numRequiredFilled++;
    }
  }
};

function useWorksheetProgress(study: Study) {
  const { worksheets } = study;
  const templates = worksheets!.map((w) => w!.templateVersion);

  const schemas = useMemo(
    () => templates!.map((t) => templateVersionToSchema(t!)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates?.map((t) => t!.id).join('/')],
  );

  return useMemo(() => {
    const progress = { numRequiredFilled: 0, numRequired: 0 };

    for (const [idx, worksheet] of worksheets!.entries()) {
      const schema = schemas[idx];
      accumulateProgress(
        schema.describe(),
        deserializeFromSchema(worksheet, schema!).values,
        progress,
      );
    }
    return progress;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksheets]);
}

const useShowProgressIndicator = (study: Study) => {
  const { finalizationMode } = useStudyFinalization(study);

  const willRequestFinalization =
    finalizationMode === FinalizationMode.REQUEST_FINALIZATION;

  const waitingForFinalSignature =
    study.isPendingFinalization &&
    !!study.finalizationRequests?.length &&
    willRequestFinalization;

  return !study.finalizedAt && !waitingForFinalSignature;
};

function ProgressIndicator({ study }: { study: Study }) {
  const worksheetProgress = useWorksheetProgress(study);

  const { restrictions } = useStudyFinalization(study);

  const canUsePatientIdRequiredToFinalize = useVariation(
    'patient-id-required-signing',
  );

  const progress: Array<[valid: boolean, text: ReactNode]> = [];

  if (study.archive!.worklistRequiredToFinalize) {
    progress.push([
      !restrictions[StudyFinalizationRestriction.WORKLIST_REQUIRED],
      <FormattedMessage
        id="worksheetProgress.worklistRequired"
        defaultMessage="Patient from Worklist"
      />,
    ]);
  }
  if (
    canUsePatientIdRequiredToFinalize &&
    !study.archive!.worklistRequiredToFinalize &&
    study.archive!.patientIdRequiredToFinalize
  ) {
    progress.push([
      !restrictions[StudyFinalizationRestriction.PATIENT_ID_REQUIRED],
      <FormattedMessage
        id="worksheetProgress.patientIdRequired"
        defaultMessage="Patient ID"
      />,
    ]);
  }

  if (study.archive!.worksheetRequiredToFinalize || study.worksheets!.length) {
    progress.push([
      !restrictions[StudyFinalizationRestriction.WORKSHEET_REQUIRED] &&
        worksheetProgress.numRequiredFilled >= worksheetProgress.numRequired,
      <>
        <FormattedMessage
          tagName="div"
          id="worksheetProgress.worksheetRequired"
          defaultMessage="Worksheet"
        />
        {!!worksheetProgress.numRequired && (
          <Text color="body" variant="sm">
            <FormattedMessage
              id="worksheetProgress.questionsAnswered"
              defaultMessage="{numRequiredFilled} of {numRequired} required questions answered."
              values={worksheetProgress}
            />
          </Text>
        )}
      </>,
    ]);
  }

  if (study.archive!.examTypeRequiredToFinalize) {
    progress.push([
      !restrictions[StudyFinalizationRestriction.EXAM_TYPE_REQUIRED],
      <FormattedMessage
        id="worksheetProgress.examTypeRequired"
        defaultMessage="Exam type"
      />,
    ]);
  }

  progress.push([
    !restrictions[StudyFinalizationRestriction.AUTHOR_REQUIRED],
    <FormattedMessage
      id="worksheetProgress.authorRequired"
      defaultMessage="Author"
    />,
  ]);

  const percent =
    (progress.filter((p) => p[0] === true).length +
      worksheetProgress.numRequiredFilled) /
    (progress.length + worksheetProgress.numRequired);

  const percentComplete = <FormattedNumber style="percent" value={percent} />;

  return (
    <Popover.Trigger
      variant="dark"
      placement="top-start"
      className="max-w-none"
      popover={
        <>
          <Heading>
            <FormattedMessage
              id="worksheetProgress.progress"
              defaultMessage="{percentComplete} Complete"
              values={{ percentComplete }}
            />
          </Heading>
          <ul className="text-headline text-base">
            {progress.map(([valid, content], idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={idx} className="flex items-start">
                {valid ? checkIcon : closeIcon}
                <span className="ml-2">{content}</span>
              </li>
            ))}
            <li>
              {study.finalizedAt ? checkIcon : closeIcon}
              <span className="ml-2">
                <FormattedMessage {...actionMessages.sign} />
              </span>
            </li>
          </ul>
        </>
      }
    >
      <span
        className="flex space-x-1 w-full items-center mr-4"
        data-bni-id="FinalizationProgress"
      >
        {percent === 1 ? (
          <FormattedMessage defaultMessage="Ready to Sign" id="readyToSign" />
        ) : (
          <>
            <ProgressBar
              value={percent * 100}
              className="w-full"
              css={css`
                max-width: 10.5rem;
              `}
              variant="secondary"
            />
            <Text color="body">{percentComplete}</Text>
          </>
        )}
      </span>
    </Popover.Trigger>
  );
}

function FinalizationControl({
  setIsPollingNumStudies,
  study,
}: {
  setIsPollingNumStudies: (isPolling: boolean) => void;
  study: Study;
}) {
  const { formatMessage } = useIntl();
  const { validateWorksheets, finalizationErrors, navbarMeta } =
    useExamContext();

  const { setShowErrors } = useExamSetterContext();

  const { finalize, finalizationMode, restrictions } =
    useStudyFinalization(study);

  const [requestFinalizationMode, setRequestFinalizationMode] = useState<
    false | true | 'next'
  >(false);
  const examRoutes = useExamRoutes();
  const { router, match } = useRouter();

  const { studyListNavConfig } = match.location.state || {};

  const isScribeAndCannotFinalizeAsScribe =
    !!restrictions[StudyFinalizationRestriction.SCRIBE_CANNOT_SIGN];

  const willRequestFinalization =
    finalizationMode === FinalizationMode.REQUEST_FINALIZATION;

  const { nextExamHandle, nextExamCursor, nextExamOrgSlug } = navbarMeta || {};

  const hasNext = nextExamHandle && nextExamCursor && nextExamOrgSlug;

  const handleSignClick = async (next: boolean) => {
    setShowErrors(true);
    const studyId = study.id;
    const organizationId = study.organization?.id;

    let analyticsEvent: AnalyticsEvent;
    if (next) {
      analyticsEvent = 'signAndNextClicked';
    } else if (willRequestFinalization) {
      analyticsEvent = 'signClicked';
    } else {
      analyticsEvent = 'signFinalClicked';
    }

    Analytics.track(analyticsEvent, { studyId, organizationId });

    const worksheetsValid = await validateWorksheets();
    if (!worksheetsValid) {
      Analytics.track('worksheetInvalid', {
        studyId,
        organizationId,
        finalizationErrors,
      });
      return false;
    }

    if (willRequestFinalization && restrictions.isValid) {
      setRequestFinalizationMode(hasNext && next ? 'next' : true);
      return false;
    }

    const finalized = await finalize();

    if (finalized) {
      // there should be no errors anymore but we need to reset the state
      // so QA errors don't show as the user edits if they move right to QA
      setShowErrors(false);
      setIsPollingNumStudies(true);
    }
    return finalized;
  };

  const waitingForFinalSignature =
    study.isPendingFinalization &&
    !!study.finalizationRequests?.length &&
    willRequestFinalization;

  return (
    <>
      {waitingForFinalSignature ? (
        <div className="flex flex-col items-end btn-toolbar">
          <div>
            <FormattedMessage
              id="examPageSidebarFooter.awaitingSignature"
              defaultMessage="Awaiting final signature"
            />{' '}
            <Text variant="body-bold" color="headline">
              {study.finalizationRequests![0]?.recipient?.name}{' '}
              {study.finalizationRequests!.length > 1 &&
                `+${study.finalizationRequests.length - 1}`}
            </Text>
          </div>
          <Button
            size="flush"
            variant="text-primary"
            data-bni-id="AddMoreButton"
            onClick={() => setRequestFinalizationMode(true)}
          >
            <FormattedMessage
              id="examPageSidebarFooter.addMore"
              defaultMessage="Add more"
            />
          </Button>
        </div>
      ) : (
        <>
          <Tooltip.Trigger
            show={isScribeAndCannotFinalizeAsScribe ? undefined : false}
            tooltip={
              <FormattedMessage
                id="examPageSidebarFooter.scribeError"
                defaultMessage="You are listed as a scribe on this study. Scribes can not sign studies."
              />
            }
          >
            <span
              /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
              tabIndex={isScribeAndCannotFinalizeAsScribe ? 0 : undefined}
            >
              <Button
                size="lg"
                variant="primary"
                data-bni-id="SignButton"
                disabled={isScribeAndCannotFinalizeAsScribe}
                className={clsx(
                  isScribeAndCannotFinalizeAsScribe && 'pointer-events-none',
                  'w-40 shadow-md',
                )}
                onClick={() => handleSignClick(false)}
              >
                {willRequestFinalization ? (
                  <Tooltip.Trigger tooltip={formatMessage(examMessages.sign)}>
                    <Text truncate>
                      <FormattedMessage {...examMessages.sign} />
                    </Text>
                  </Tooltip.Trigger>
                ) : (
                  <Tooltip.Trigger
                    tooltip={formatMessage(examMessages.signFinal)}
                  >
                    <Text truncate>
                      <FormattedMessage {...examMessages.signFinal} />
                    </Text>
                  </Tooltip.Trigger>
                )}
              </Button>
            </span>
          </Tooltip.Trigger>
          <PromptButton
            size="lg"
            variant="secondary"
            data-bni-id="SignAndNextButton"
            disabled={isScribeAndCannotFinalizeAsScribe || !hasNext}
            className={clsx(
              isScribeAndCannotFinalizeAsScribe && 'pointer-events-none',
              'mr-2 w-40 border-none flex-shrink-0 shadow-md',
            )}
            onClick={async () => {
              const finalized = await handleSignClick(true);
              if (finalized && hasNext)
                router.replace({
                  pathname: examRoutes.exam({
                    organizationSlug: nextExamOrgSlug!,
                    studyHandle: nextExamHandle!,
                  }),
                  state: {
                    studyListNavConfig: {
                      ...studyListNavConfig,
                      cursor: nextExamCursor,
                    },
                  },
                });
            }}
          >
            <Tooltip.Trigger tooltip={formatMessage(examMessages.signAndNext)}>
              <Text truncate>
                <FormattedMessage {...examMessages.signAndNext} />
              </Text>
            </Tooltip.Trigger>
          </PromptButton>
        </>
      )}
      <RequestFinalizationModal
        study={study}
        show={!!requestFinalizationMode}
        onHide={() => {
          setRequestFinalizationMode(false);
          Analytics.track('attestationModalDismissed', {
            studyId: study.id,
            organizationId: study.organization?.id,
          });
        }}
        onCompleted={() => {
          if (requestFinalizationMode === 'next' && hasNext) {
            router.replace({
              pathname: examRoutes.exam({
                organizationSlug: nextExamOrgSlug!,
                studyHandle: nextExamHandle!,
              }),
              state: {
                studyListNavConfig: {
                  ...studyListNavConfig,
                  cursor: nextExamCursor,
                },
              },
            });
          }
          setRequestFinalizationMode(false);
        }}
      />
    </>
  );
}

interface Props
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  study: Study;
  onQaSubmit: () => Promise<boolean>;
  qaReadOnly: boolean;
  relay: RelayProp;
}

function ExamPageSidebarFooter({
  study,
  qaReadOnly,
  onQaSubmit,
  relay,
  ...props
}: Props) {
  // const canUseGranularQaNotifications = useVariation(
  //   'granular-qa-notifications',
  // );
  const { setRestrictions } = useExamSetterContext();
  const { restrictions } = useStudyFinalization(study);
  useLayoutEffect(() => {
    setRestrictions(restrictions);
  }, [restrictions, setRestrictions]);

  const { showQa } = useSections(study);
  const canFinalize = canFinalizeStudy(study);
  const showProgressIndicator = useShowProgressIndicator(study);

  const viewer = useViewerContext();
  const { setIsPollingNumStudies } =
    usePollStudies<ExamPageSidebarFooter_NumStudiesQuery$data>({
      queryFn: () =>
        fetchQuery<RefreshNumStudiesQuery>(
          relay.environment,
          graphql`
            query ExamPageSidebarFooter_NumStudiesQuery {
              tenant {
                numNeedsReview: numStudies(
                  search: {
                    status: [NEEDS_REVIEW]
                    excludedFromStudyLists: [false]
                  }
                )
              }
            }
          `,
          {},
        ).toPromise(),
      viewerId: viewer.id,
      organizationId: study.organization!.id,
    });

  if (showProgressIndicator || canFinalize)
    return (
      <div {...props}>
        {canFinalize && (
          <FinalizationControl
            study={study}
            setIsPollingNumStudies={setIsPollingNumStudies}
          />
        )}
        {showProgressIndicator && <ProgressIndicator study={study} />}
      </div>
    );

  if (
    showQa &&
    // we don't have the ability yet to tell who was previously notified
    // (!qaReadonly || canUseGranularQaNotifications) will replace:
    !qaReadOnly
  )
    return (
      <div {...props}>
        <ExamPageSidebarQaFooter
          study={study}
          readonly={qaReadOnly}
          onSubmit={onQaSubmit}
        />
      </div>
    );

  return null;
}

export default createFragmentContainer(ExamPageSidebarFooter, {
  study: graphql`
    fragment ExamPageSidebarFooter_study on Study {
      id
      organization {
        id
        slug
      }
      finalizedAt
      isPendingFinalization
      finalizationRequests {
        recipient {
          id
          name
        }
      }
      archive {
        worklistRequiredToFinalize
        patientIdRequiredToFinalize
        worksheetRequiredToFinalize
        examTypeRequiredToFinalize
        defaultQaNotificationRecipients
      }
      worksheets {
        id
        templateVersion {
          id
          ...worksheet_templateVersion
        }
        values
      }
      ...useStudyFinalization_study
      ...StudyPermissions_canFinalizeStudy
      ...examPageSidebar_useSections_study
      ...StudyPermissions_studyReviewStatus
      ...RequestFinalizationModal_study
      ...ExamPageSidebarQaFooter_study
    }
  `,
});
